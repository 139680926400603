import React from "react";
import styles from './UnderMaintenance.module.scss';
import { Container } from "postcss";
import logo from '../media/logo/Asset 17.svg';
const UnderMaintenance: React.FC = () => {

    return (
        <div className={styles.container}>

            <div className={styles.topBar}>
                <div className={styles.navlogo}>
                    <div className={styles.navLogo}>
                        <img src={logo} alt="Logo" />
                    </div>
                    <div className={styles.navLogoName}>
                        <p className={styles.logoText}>FairCloud {<span className='font-bold'>AI</span>}</p>
                    </div>
                </div>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.title}>We Are Upgrading.</div>
                <div className={styles.title}> More <span className={styles.gradientText}>Savings</span> Incoming.</div>
            </div>
        </div>
    )
}

export default UnderMaintenance;